@import "https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600&display=swap";

:root {
  --font-primary: "Public Sans", helvetica, arial, sans-serif;
  --shadow-color: 0deg 0% 65%;
  --shadow-elevation-medium:
  0px 0.5px 0.6px hsl(var(--shadow-color) / 0.36),
  0px 1.5px 1.7px -0.8px hsl(var(--shadow-color) / 0.36),
  -0.1px 3.8px 4.3px -1.7px hsl(var(--shadow-color) / 0.36),
  -0.2px 9.2px 10.4px -2.5px hsl(var(--shadow-color) / 0.36);
}

/* stylelint-disable function-comma-newline-after */
header {
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 4;
  padding: 0;
  box-shadow: var(--shadow-elevation-medium);
  transition-property: background-color, padding, font-size;
  transition-duration: 0.2s;
  zoom: 1;
  height: 58px;
}

header::before,
header::after {
  content: "";
  display: table;
}

header::after {
  clear: both;
}

header .menu-button {
  display: none;
  float: left;
  width: 66px;
  height: 100%;
  padding: 13px 0;
  text-align: center;
}

header .menu-button:active {
  background-color: #DDDDDD;
}

header .menu-button svg,
header .menu-button:visited svg {
  fill: black;
  width: 32px;
}

header a,
header div.hierarchy-divider {
  display: table-cell;
  vertical-align: middle;
  color: #0D2481;
  font-family: var(--font-primary);
  padding: 0 12px;
}

header div.org-location-container div.header-dropdown-container a.hierarchy-level-name {
  display: inline-block;
  position: relative;
}

header div.hierarchy-divider {
  padding: 0 2px;
}

header .org-name,
header div.hierarchy-divider {
  padding-top: 2px;
}

header div.header-dropdown-container {
  vertical-align: middle;
  display: table-cell;
}

header div.header-dropdown-container .dropdown-menu {
  min-width: 300px;
}

header div.header-dropdown-container .dropdown-menu.show {
  max-height: 300px;
}

header div.header-dropdown-container .dropdown-menu .search {
  padding: 0 10px;
}

header div.header-dropdown-container .dropdown-menu .dropdown-header {
  font-size: 12px;
  padding: 10px 10px 3px;
}

header div.header-dropdown-container .dropdown-menu li.other-model {
  padding: 2px 10px;
  font-size: 14px;
  text-align: left;
}

header div.header-dropdown-container .dropdown-menu li.other-model a {
  display: inline;
  padding: 0;
}

header div.header-dropdown-container .dropdown-menu li.other-model a.order-channel-link {
  display: inline-block;
  padding: 0;
}

header div.header-dropdown-container .dropdown-menu li.other-model a:first-of-type {
  vertical-align: middle;
}

header div.header-dropdown-container .dropdown-menu li a:first-of-type pre {
  display: contents;
  font-size: 1px;
}

header div.header-dropdown-container .dropdown-menu li a:first-of-type .badge-img {
  width: 15px;
  height: 15px;
}

header div.header-dropdown-container a.hierarchy-level-name {
  padding-top: 7px;
  padding-bottom: 15px;
}

@media (max-width: 560px) {
  header div.header-dropdown-container .dropdown-menu {
    left: -60px;
  }

  header div.header-dropdown-container a.hierarchy-level-name {
    padding-top: 0;
    padding-bottom: 0;
  }
}

header a,
header a:hover,
header a:active,
header a:focus,
header a:visited {
  text-decoration: none;
  outline: none;
}

header .logo {
  margin-left: 4px;
  height: 100%;
}

header .logo span {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 80px;
  height: 58px;
  display: block;
}

.prod.heroku header .logo span {
  background-image: url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-heroku.png");
  background-image:
    image-set(
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-heroku.png") 1x,
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-heroku@2x.png") 2x
    );
}

.staging.heroku header .logo span {
  background-image: url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-staging-heroku.png");
  background-image:
    image-set(
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-staging-heroku.png") 1x,
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-staging-heroku@2x.png") 2x
    );
  width: 120px;
}

.sandbox.heroku header .logo span {
  background-image: url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-sandbox-heroku.png");
  background-image:
    image-set(
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-sandbox-heroku.png") 1x,
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-sandbox-heroku@2x.png") 2x
    );
  width: 120px;
}

.prod.porter header .logo span {
  background-image: url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-porter.png");
  background-image:
    image-set(
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-porter.png") 1x,
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-porter@2x.png") 2x
    );
}

.staging.porter header .logo span {
  background-image: url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-staging-porter.png");
  background-image:
    image-set(
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-staging-porter.png") 1x,
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-staging-porter@2x.png") 2x
    );
  width: 120px;
}

.sandbox.porter header .logo span {
  background-image: url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-sandbox-porter.png");
  background-image:
    image-set(
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-sandbox-porter.png") 1x,
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-sandbox-porter@2x.png") 2x
    );
  width: 120px;
}

.dev header .logo span {
  background-image: url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-dev.png");
  background-image:
    image-set(
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-dev.png") 1x,
      url("https://static.bureau.getbite.com/images/bite-logo-2024-orange-dev@2x.png") 2x
    );
  width: 120px;
}

.staging header {
  background: linear-gradient(0deg, rgba(255 255 255 / 1) 0%, rgb(255 255 255 / 1) 30%, rgb(248 205 219 / 1) 100%);
  border-bottom: 2px solid #F31F63;
}

.sandbox header {
  background: linear-gradient(0deg, rgb(255 255 255 / 1) 0%, rgb(179 230 252 / 1) 100%);
  border-bottom: 2px solid #4DD2FB;
}


.dev header {
  background: linear-gradient(0deg, rgb(255 255 255 / 1) 0%, rgb(255 255 255 / 1) 30%, rgb(211 225 248 / 1) 100%);
  border-bottom: 2px solid #3B82F6;
}

header .org-location-container {
  display: table-cell;
  padding: 0;
  position: relative;
}

header .org-name,
header div.header-dropdown-container a.hierarchy-level-name {
  line-height: 1.25;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

header .org-name span,
header div.header-dropdown-container a.hierarchy-level-name span {
  display: block;
  word-break: break-word;
  overflow: hidden;
  max-height: 58px;
}

header div.header-dropdown-container a.hierarchy-level-name span.name {
  display: box;
  font-weight: bold;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 12px;
}

header .org-location-container div.header-dropdown-container a.hierarchy-level-name span.hierarchy-level {
  font-size: 9px;
}

header div.header-dropdown-container a.hierarchy-level-name .dropdown-arrow {
  position: absolute;
  right: 12px;
  top: calc(55% - 2px);
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

header div.header-dropdown-container.current-level a.hierarchy-level-name span.name,
header div.header-dropdown-container.current-level a.hierarchy-level-name .dropdown-arrow {
  color: #FF7401;
}

header .dropdown-menu > li.other-model:hover,
header .dropdown-menu > li.other-model:active,
header .dropdown-menu > li.other-model:focus {
  background-color: #EEEEEE !important;
}

header .dropdown-menu > li.other-model:hover a:first-of-type,
header .dropdown-menu > li.other-model:active a:first-of-type,
header .dropdown-menu > li.other-model:focus a:first-of-type {
  background-image: none;
  color: #FF7401 !important;
  text-decoration: none;
}

header div.header-dropdown-container .dropdown-menu li.other-model a.order-channel-link:hover .order-channel-tag,
header div.header-dropdown-container .dropdown-menu li.other-model a.order-channel-link:active .order-channel-tag,
header div.header-dropdown-container .dropdown-menu li.other-model a.order-channel-link:focus .order-channel-tag {
  background-color: #FF7401;
  color: white;
}

header a:hover,
header a:active {
  background-color: rgb(255 255 255 / 0.4);
  color: #FF7401;
}

@media (max-width: 560px) {
  header .menu-button {
    display: table-cell;
  }

  header .logo {
    margin-left: 0;
  }

  header a {
    font-size: 18px;
    color: #666666;
  }

  header .org-location-container .org-name {
    display: block;
    font-size: 12px;
    padding-top: 6px;
  }

  header .org-location-container .hierarchy-divider {
    display: none;
  }

  header .org-location-container div.header-dropdown-container a.hierarchy-level-name {
    font-size: 22px;
  }

  header .org-location-container div.header-dropdown-container a.hierarchy-level-name span.hierarchy-level {
    display: none;
  }

  header .org-location-container div.header-dropdown-container:nth-child(5) {
    display: none;
  }
}
