@import "https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap";

:root {
  --font-primary: "Public Sans", helvetica, arial, sans-serif;
  --font-kiosk-device-name: "Oswald", "HelveticaNeue-CondensedBold", "Arial Narrow", sans-serif;
}

.kiosk-list .list-group-item {
  border: none;
}

.kiosk-card {
  margin: 6px;
  border: lightgrey 1px solid;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0.16);
}

.expo-printer-status,
.kiosk-status {
  width: 100%;
  color: #4A4A4A;
  font-size: 14px;
  font-family: var(--font-primary);
}

.expo-printer-status .status-row,
.kiosk-status .status-row {
  display: table;
  width: 100%;
}

.expo-printer-status .section,
.kiosk-status .section {
  padding: 8px;
}

.expo-printer-status .section .header,
.kiosk-status .section .short .header {
  display: flex;
}

.expo-printer-status .device-icon,
.kiosk-status .device-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 30px;
  margin-right: 4px;
  width: 32px;
}

.expo-printer-status .printer,
.kiosk-status .ipad {
  width: 100%;
  display: table-cell;
}

.kiosk-status .ipad .btn-group.on-off {
  margin-right: 4px;
  max-height: 33px;
}

.kiosk-status .section.ipad .device-icon {
  background-image: url("https://static.bureau.getbite.com/images/status-kiosk-default@2x.png");
}

.expo-printer-status .section .device-identifier,
.kiosk-status .section .device-identifier {
  flex: 1;
}

.kiosk-status .section .device-identifier .subtitle {
  vertical-align: middle;
}

.expo-printer-status .section .device-icon.multi-line,
.kiosk-status .section .device-icon.multi-line {
  height: 40px;
  width: 40px;
}

.expo-printer-status .section .device-name.multi-line,
.kiosk-status .section .device-name.multi-line {
  line-height: 1;
  margin-top: -2px;
}

.expo-printer-status .section .device-name span.name-span::after,
.kiosk-status .section .device-name span.name-span::after {
  content: "\25CF\0020";
  margin-left: 6px;
  opacity: 0;
}

.expo-printer-status .printer .device-name,
.kiosk-status .ipad .device-name {
  font-family: var(--font-kiosk-device-name), sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.expo-printer-status .section .device-name span.span.name-span::after,
.kiosk-status .section .device-name span.name-span.good::after {
  color: green;
  opacity: 1;
}

.expo-printer-status .section .device-name span.name-span.errors::after,
.kiosk-status .section .device-name span.name-span.errors::after {
  color: #D0011B;
  opacity: 1;
}

.expo-printer-status .section .device-name span.name-span.warnings::after,
.kiosk-status .section .device-name span.name-span.warnings::after {
  color: #F6A623;
  opacity: 1;
}

.kiosk-status .section .device-name span.subtitle-span {
  display: block;
  font-size: 10px;
}

.kiosk-status .section .device-name span.in-use-badge {
  color: white;
  background: #F6A623;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 13px;
  vertical-align: text-bottom;
  display: inline-block;
}

.kiosk-status .section .device-name.multi-line span.in-use-badge {
  vertical-align: top;
}

.kiosk-status span[emoji]::before {
  content: attr(emoji);
  margin-right: 4px;
  vertical-align: middle;
}

.kiosk-status .ipad .device-type {
  background-color: #9B9B9B;
  border-radius: 2px;
  color: white;
  display: inline-block;
  font-weight: 700;
  font-size: 10px;
  padding: 2px 6px;
  text-transform: capitalize;
  margin-right: 5px;
}

.expo-printer-status .printer .header .timestamp,
.kiosk-status .ipad .short .header .timestamp {
  color: #9B9B9B;
  display: table;
  font-size: 13px;
  margin: 2px 6px 2px 2px;
  min-width: 64px;
  text-align: right;
  padding: 4px 8px;
}

.expo-printer-status .printer .header .timestamp.very-old,
.kiosk-status .ipad .short .header .timestamp.very-old {
  font-weight: 500;
  color: #FFFFFF;
  background-color: #D0011B;
  border-radius: 4px;
  height: 20px;
}

.kiosk-status.no-status .ipad .short .header .timestamp {
  color: #D0011B;
  font-weight: 500;
}

.expo-printer-status .printer .header .timestamp.old,
.kiosk-status .ipad .short .header .timestamp.old {
  color: #F6A623;
}

.kiosk-status .ipad .dropdown.actions {
  border: #999999 1px solid;
  border-radius: 4px;
  height: 32px;
  padding: 0;
  width: 32px;
}

.kiosk-status .ipad .dropdown.actions:active,
.kiosk-status .ipad .dropdown.actions:hover {
  background-color: #EEEEEE;
}

.kiosk-status .ipad .dropdown.actions button {
  display: block;
  font-size: 18px;
  padding: 0 4px;
  background-color: white;
  color: black;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 4px;
  line-height: 1;
  text-align: center;
}

.kiosk-status .ipad .dropdown.actions .critical a {
  color: #D0011B;
}

.kiosk-status .ipad .dropdown.actions hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.kiosk-status .ipad .dropdown.actions .header {
  cursor: auto;
}

.kiosk-status .ipad .dropdown.actions .header span {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.4285;
  color: #999999;
  white-space: nowrap;
}

.kiosk-status .status-row .peripheral {
  width: 50%;
  display: table-cell;
  border-top: 1px solid rgb(241 241 241);
}

.kiosk-status .status-row .peripheral .device-name {
  font-size: 18px;
}

.expo-printer-status .status-row .printer .device-icon,
.kiosk-status .status-row .peripheral.printer .device-icon {
  background-image: url("https://static.bureau.getbite.com/images/status-printer-default@2x.png");
}

.kiosk-status .status-row .peripheral.payment .device-icon {
  background-image: url("https://static.bureau.getbite.com/images/status-payment-default@2x.png");
}

.kiosk-status .status-row .peripheral.scanner .device-icon {
  background-image: url("https://static.bureau.getbite.com/images/status-scanner-icon@2x.png");
}

.kiosk-status .status-row .peripheral.msr .device-icon {
  background-image: url("https://static.bureau.getbite.com/images/status-msr-icon@2x.png");
}

.kiosk-status .status-row .peripheral.hid .device-icon {
  background-image: url("https://static.bureau.getbite.com/images/status-hid@2x.png");
}

.kiosk-status .status-row .peripheral.scale .device-icon {
  background-image: url("https://static.bureau.getbite.com/images/status-scale-icon@2x.png");
}

.kiosk-status .status-row .peripheral.storm .device-icon {
  background-image: url("https://static.bureau.getbite.com/images/status-storm-icon@2x.png");
}

.kiosk-status .status-row .peripheral:first-child {
  border-right: 1px solid rgb(241 241 241);
}

.kiosk-status .long > .errors span {
  color: #D0011B;
}

.kiosk-status .long > .warnings span {
  color: #F6A623;
}

.kiosk-status .connected-dot::before {
  content: "\25CF\0020";
}

.kiosk-status .connected-dot.online {
  color: green !important;
}

.kiosk-status .connected-dot.warning {
  color: #F6A623 !important;
}

.kiosk-status .connected-dot.offline {
  color: #D0011B !important;
}

.kiosk-status .short .fineprint {
  display: block;
}

.kiosk-status .short .fineprint:empty {
  display: none;
}

.kiosk-status .fineprint,
.kiosk-status .fineprint-extra {
  color: #9E9E9E;
  font-size: 12px;
  margin-top: 8px;
}

.kiosk-status .fineprint > span,
.kiosk-status .details > span,
.kiosk-status .errors > span,
.kiosk-status .warnings > span {
  display: block;
  line-height: 1.6;
}

.kiosk-status .fineprint-extra .toggle .title {
  margin-right: 3px;
}

.kiosk-status .fineprint-extra .toggle .title:empty {
  display: none;
}

.kiosk-status .fineprint-extra .toggle .title.has-extras {
  text-decoration: underline;
  cursor: pointer;
}

.kiosk-status .fineprint-extra .toggle .arrow {
  display: inline-block;
  font-size: 8px;
  transition: all 0.3s ease;
}

.kiosk-status span.icon {
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
}

.kiosk-status span.icon.bluetooth {
  background-image: url("https://static.bureau.getbite.com/images/status-bluetooth-icon@2x.png");
  padding-left: 12px;
}

.kiosk-status span.icon.wifi {
  background-image: url("https://static.bureau.getbite.com/images/status-wifi-icon@2x.png");
  padding-left: 22px;
}
