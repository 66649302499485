body.register-user-view {
  overflow: hidden;
}

body.register-user-view #app-view {
  margin-top: 0;
}

#app-view .register-user {
  margin: 50px auto;
  width: 500px;
  background-color: #f58538;
}

@media (max-width: 560px) {
  #app-view .register-user {
    width: 87%;
  }
}

#app-view .register-user .card-body {
  text-align: center;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 0.30);
}

#app-view .register-user .card-body .logo {
  background: url("https://static.bureau.getbite.com/images/bite-logo-2024-white.png");
  background-image:
    image-set(
      /* stylelint-disable-next-line function-comma-newline-after */
      url("https://static.bureau.getbite.com/images/bite-logo-2024-white.png") 1x,
      url("https://static.bureau.getbite.com/images/bite-logo-2024-white@2x.png") 2x);
  width: 100%;
  height: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

#app-view .register-user .card-body .message {
  display: none;
  width: 80%;
  margin: 0 auto 20px;
}

#app-view .register-user .card-body input {
  text-align: center;
  width: 80%;
  margin: 0 auto 20px;
}

#app-view .register-user .card-body .input-group {
  width: 100%;
}

button.submit {
  width: 200px;
  margin: 0 auto 20px;
}
